import React, { useCallback, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Button, Grid } from 'semantic-ui-react'
import { Spinner } from 'react-activity'
import { format, parseISO } from 'date-fns'
import { navigate } from 'gatsby'
import { useLocation } from '@reach/router'

import BackgroundImage from 'gatsby-background-image'
import Selectors from 'src/state/root-selectors'
import { getPreviewVideoSrc, getWorkoutsStatsById } from 'src/utils/sdk-utils'
import { contentfulObjectNamesToString } from 'src/utils/data-utils'
import { useSaveUrl } from '../../utils/url-utils'
import { useBreakpoints, Directions, Breakpoints } from 'src/utils/breakpoint-utils'
import { FavoriteCollectionName, IState, IWorkoutTypes } from '@pvolve/sdk/src/redux/selectors'
import { ContentfulWorkout_Details } from 'src/utils/graphql'

import {
    PreviewVideoPlayer,
    DetailsCard,
    FavoriteButton,
    Icon,
    LinkWithArrow,
    ResponsivePadding,
} from 'src/components/shared'
import * as Styles from 'src/styles/details-header-overlay.module.scss'

interface WorkoutHeaderOverlayProps {
    workout: ContentfulWorkout_Details
}

const WorkoutHeaderOverlay = ({ workout }: WorkoutHeaderOverlayProps) => {
    const entitled = useSelector(Selectors.auth.entitled)

    const {
        contentful_id: workoutId,
        bodyFocus: workoutBodyFocuses,
        benefit: workoutBenefits,
        workoutType: workoutTypes,
        name: workoutName,
        level: workoutLevel,
        thumbnail: workoutThumbnail,
        slug,
        series,
    } = workout

    const [previewVideoSrc, setPreviewVideoSrc] = useState<string>()
    const [previewVideoFetched, setPreviewVideoFetched] = useState(false)
    const [statsHistory, setStatsHistory] = useState<any>()

    const location = useLocation()
    const saveUrl: () => void = useSaveUrl('initialUrl')

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        async function loadPreviewVideo() {
            try {
                const { uri } = await getPreviewVideoSrc(workoutId, 'workout')

                if (uri) {
                    setPreviewVideoSrc(uri)
                }
            } catch (error) {
                console.log({ error })
            } finally {
                setPreviewVideoFetched(true)
            }
        }

        loadPreviewVideo()
    }, [])

    useEffect(() => {
        const fetchStats = async () => {
            try {
                // TODO: This belongs in redux
                const statsV2 = await getWorkoutsStatsById(workoutId)
                setStatsHistory(statsV2)
            } catch (error) {}
        }

        if (entitled) {
            fetchStats()
        }
    }, [entitled])

    const lastCompleted = statsHistory?.stats?.last_started_at || null
    const timesCompleted = statsHistory?.stats?.total_completed || 0
    const lastCompletedDate = lastCompleted ? format(parseISO(lastCompleted), 'MM.dd.yy') : undefined
    const strBodyFocuses = contentfulObjectNamesToString(workoutBodyFocuses || [])
    const strTypes = contentfulObjectNamesToString(workoutTypes || [])
    const strBenefits = contentfulObjectNamesToString(workoutBenefits || [])

    const workoutDescription = workout.childContentfulWorkoutDescriptionTextNode
        ? workout.childContentfulWorkoutDescriptionTextNode.description
        : 'Description Coming Soon'

    const handleVideoButtonClick = useCallback(() => {
        if (entitled) {
            saveUrl()
        }
        navigate(`/workouts/${slug}/video`, { state: { prevPath: location.pathname } })
    }, [slug])

    const showVideoButton = entitled || previewVideoSrc

    const isMobile = useBreakpoints(Breakpoints.lg, Directions.down)
    const isTabletPortrait = useBreakpoints(Breakpoints.lg, Directions.up)

    const isTalk = workout.type === IWorkoutTypes.talk

    const Header = (
        <div className={Styles.header}>
            <h1 className="bold upper margin-bottom--0">{workoutName}</h1>
            <p className={`${Styles.level} accent`}>{workoutLevel}</p>
            <div className={Styles.buttonWrapper}>
                {showVideoButton && (
                    <Button size="big" onClick={handleVideoButtonClick} className={Styles.playButton} icon>
                        <Icon className={Styles.playButtonIcon} size={6} name="pv-play" />
                        {entitled ? 'Play' : 'Preview'}
                    </Button>
                )}
                {entitled && !isTalk && (
                    <FavoriteButton
                        className={Styles.favButton}
                        collection={FavoriteCollectionName.workouts}
                        id={workoutId}
                    />
                )}
            </div>
        </div>
    )

    return (
        <div className={Styles.wrapper}>
            <div className={Styles.thumbnailWrapper}>
                {previewVideoSrc && <PreviewVideoPlayer src={previewVideoSrc} className={Styles.previewVideo} />}
                {!previewVideoSrc && !previewVideoFetched && (
                    <div className={Styles.spinner}>
                        <Spinner size={40} speed={0.75} />
                    </div>
                )}
                {!previewVideoSrc && previewVideoFetched && (
                    <BackgroundImage
                        className={Styles.thumbnail}
                        fluid={workoutThumbnail.fluid}
                        backgroundColor="#000"
                    ></BackgroundImage>
                )}

                {isTabletPortrait && Header}
            </div>

            {!isTabletPortrait && Header}

            <ResponsivePadding>
                <Grid columns={isMobile || isTalk ? 1 : 2}>
                    <Grid.Row>
                        <Grid.Column className={`${Styles.description}`}>
                            <p className="p1">{workoutDescription}</p>
                            {series && (
                                <p className={`${Styles.descriptionLink}`}>
                                    From <LinkWithArrow to={`/series/${series.slug}`} label={series.name} />
                                </p>
                            )}
                        </Grid.Column>
                        {!isTalk && (
                            <Grid.Column>
                                <div className={Styles.detailsGrid}>
                                    <DetailsCard title="focus" text={strBodyFocuses} icon="pv-target" />
                                    <DetailsCard
                                        title="type"
                                        text={strTypes}
                                        type={workout.workoutType}
                                        icon="pv-flag"
                                    />
                                    <DetailsCard title="benefits" text={strBenefits} icon="pv-progress-arrow" />
                                    <DetailsCard
                                        title="times_completed"
                                        recentlyDate={lastCompletedDate}
                                        numTimesCompleted={timesCompleted}
                                    />
                                </div>
                            </Grid.Column>
                        )}
                    </Grid.Row>
                </Grid>
            </ResponsivePadding>
        </div>
    )
}

export default WorkoutHeaderOverlay
